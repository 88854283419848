<template>
  <ui-component-modal
    :modalTitle="'Edit settings'"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :onClickSave="saveSpace"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-fullwidth is-striped">
        <tbody>
          <tr>
            <td>Is active</td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': mMeetingtype.IsActive }">
                    <a @click="mMeetingtype.IsActive = true">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li :class="{ 'is-active': !mMeetingtype.IsActive }">
                    <a @click="mMeetingtype.IsActive = false">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Export group</td>
            <td>
              <div class="select is-small">
                <select v-model="mMeetingtype.ExportgroupId">
                  <option
                    v-for="exportgroup in exportgroups"
                    :key="exportgroup.Id"
                    :value="exportgroup.Id"
                    :selected="mMeetingtype.ExportgroupId === exportgroup.Id"
                  >
                    {{ getExportgroupName(exportgroup.Id) }}
                  </option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td>Minimum duration</td>
            <td>
              <span>
                <input
                  class="input is-small"
                  type="text"
                  placeholder="Minimum duration"
                  v-model="mMeetingtype.MinimumDuration"
                />
              </span>
              <span>hours</span>
            </td>
          </tr>
          <tr>
            <td>Hours before start</td>
            <td>
              <input
                class="input is-small"
                type="text"
                placeholder="Minimum duration"
                v-model="mMeetingtype.NrOfHoursBeforeStart"
              />
              <span>hours</span>
            </td>
          </tr>
          <tr v-if="mMeetingtype.MeetingtypeId > 1">
            <td>Capacity control</td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': mMeetingtype.UseCapacityControl }">
                    <a @click="mMeetingtype.UseCapacityControl = true">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li
                    :class="{ 'is-active': !mMeetingtype.UseCapacityControl }"
                  >
                    <a @click="mMeetingtype.UseCapacityControl = false">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr
            v-if="
              mMeetingtype.MeetingtypeId > 1 && mMeetingtype.UseCapacityControl
            "
          >
            <td>Default capacity</td>
            <td>
              <input
                class="input is-small"
                type="text"
                placeholder="Default capacity"
                v-model="mMeetingtype.Capacity"
              />
              <span>people</span>
            </td>
          </tr>
          <tr v-if="mMeetingtype.MeetingtypeId > 1">
            <td>Is shareable</td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li
                    :class="{
                      'is-active': mMeetingtype.IsShareable,
                    }"
                  >
                    <a @click="mMeetingtype.IsShareable = true">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li
                    :class="{
                      'is-active': !mMeetingtype.IsShareable,
                    }"
                  >
                    <a @click="mMeetingtype.IsShareable = false">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>Use space in utilization percentage</td>
            <td>
              <div class="tabs is-toggle is-small">
                <ul>
                  <li :class="{ 'is-active': mMeetingtype.InUsagePercentage }">
                    <a @click="mMeetingtype.InUsagePercentage = true">
                      <span>Yes</span>
                    </a>
                  </li>
                  <li :class="{ 'is-active': !mMeetingtype.InUsagePercentage }">
                    <a @click="mMeetingtype.InUsagePercentage = false">
                      <span>No</span>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import locationProvider from '@/providers/location'
import spaceProvider from '@/providers/space'

export default {
  props: {
    meetingtype: {
      default: null,
      type: Object,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      exportgroups: [],
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      locationId: Number(this.$route.params.locationId),
      mMeetingtype: JSON.parse(JSON.stringify(this.meetingtype)),
    }
  },

  created() {
    this.getLocationExportgroups()
  },

  computed: {
    ...mapState('spaceStore', ['space']),
  },

  methods: {
    ...mapMutations('spaceStore', ['setSpace']),

    getLocationExportgroups() {
      locationProvider.methods
        .getLocationExportgroups(this.locationId)
        .then((response) => {
          this.exportgroups = response.data
        })
    },

    getExportgroupName(exportgroupId) {
      let name = ''
      let exportgroupIndex = this.exportgroups.findIndex(
        (e) => e.Id === exportgroupId
      )

      if (exportgroupIndex > -1) {
        name = this.exportgroups[exportgroupIndex].Name
      }
      return name
    },

    saveSpace() {
      let self = this
      self.isSaving = true
      self.isSavingSuccess = false
      self.isSavingError = false

      let space = JSON.parse(JSON.stringify(self.space))
      let meetingtypeIndex = space.Meetingtypes.findIndex(
        (m) => m.MeetingtypeId === self.mMeetingtype.MeetingtypeId
      )

      if (meetingtypeIndex > -1) {
        space.Meetingtypes[meetingtypeIndex].IsActive =
          self.mMeetingtype.IsActive
        space.Meetingtypes[meetingtypeIndex].ExportgroupId = Number(
          self.mMeetingtype.ExportgroupId
        )
        space.Meetingtypes[meetingtypeIndex].MinimumDuration = Number(
          self.mMeetingtype.MinimumDuration
        )
        space.Meetingtypes[meetingtypeIndex].NrOfHoursBeforeStart = Number(
          self.mMeetingtype.NrOfHoursBeforeStart
        )
        //space.MeetinMeetingtypesgTypes[meetingtypeIndex].Fee = self.mMeetingtype.Fee
        space.Meetingtypes[meetingtypeIndex].InUsagePercentage =
          self.mMeetingtype.InUsagePercentage
        space.Meetingtypes[meetingtypeIndex].UseCapacityControl =
          self.mMeetingtype.UseCapacityControl
        space.Meetingtypes[meetingtypeIndex].Capacity = Number(
          self.mMeetingtype.Capacity
        )
        space.Meetingtypes[meetingtypeIndex].IsShareable =
          self.mMeetingtype.IsShareable

        spaceProvider.methods
          .updateSpace(space)
          .then((response) => {
            if (response.status === 200) {
              self.setSpace(response.data)

              setTimeout(() => {
                self.$emit('spaceMeetingtypeEdited', space)
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            //console.log(error.response)
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
            self.isSavingSuccess = true
          })
      }
    },
  },
}
</script>
